
import React from 'react';

function Bottombar() {
  return (
    <div style={styles.container}>
      <p>© 2024 Fosen Utleiepartner AS. All rights reserved.</p>
      <div style={styles.links}>
        <a href="/terms">Vilår -</a> 
        <a href="/contact"> Kontakt</a>
      </div>
    </div>
  );
}

const styles = {
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#333',
    color: '#fff',
    textAlign: 'center',
    padding: '10px',
    zIndex: 1000,
  },
  links: {
    marginTop: '10px',
  }
};

export default Bottombar;
