// src/pages/Contact.js
import React from 'react';
import contact from '../assets/contact.png';
import kart from '../assets/kart.png';
function Contact() {
  return (
<div>
  <div className="omosssamle-container">
    <div className="omoss-content">
      <div className="omoss-text">
      <h3 style={{ fontSize: '34px', fontWeight: 'bold', marginBottom: '20px' }}>Kontakt oss</h3>
<p style={{ fontSize: '22px', lineHeight: '1.5', color: '#333' }}>
  <br></br><br></br>
  Fosen Utleiepartner AS<br></br>
  Ryggaveien 292<br></br>
  7130 Brekstad<br></br>
  <br></br>
  Orgnr. 934192524<br></br>
  Tlf: 40342222<br></br>
  <a href="mailto:post@fosenup.no" style={{ color: '#007BFF', textDecoration: 'none' }}>post@fosenup.no</a><br></br>
  <br></br><br></br>
</p>

      </div>
      <div className="omoss-bilde">
        <img src={contact} alt="Liftkorg" />
      </div>
    </div>
  </div>

  <div className="ss">
    <img src={kart} alt="Kart" />
  </div>
</div>





  );
}

export default Contact;
