// src/pages/LandingPage.js
import React from 'react';
import '../App.css'; // Importerer CSS
import maskiner from '../assets/maskiner.png'; // Importerer maskiner-bildet
import lifter from '../assets/lifter.png'; // Importerer lifter-bildet
import verktoy from '../assets/verktoy.png'; // Importerer verktoy-bildet

function LandingPage() {
  return (
    <div className="landing-page">
      {/* Stort bilde for maskiner som strekkes over hele siden */}
      <div className="hero-image">
        <img src={maskiner} alt="Maskiner" />
      </div>

      {/* Innholdet under hero-bildet skal fortsatt ha en maks bredde */}
      <div className="content-container">
        <div className="content">
          <div className="text-area">
            <h1></h1>
            <p>
              Vi tilbyr pålitelig og moderne utstyr som vil gjøre jobben din enklere.<br></br>
              På vår produkstide kan du selv se når ønsket utstyr er tilgjengelig, og booke direkte på nett.<br></br><br></br>
              

              Prosessen går slik:<br></br>
              1. Finn ønsket utstyr på vår produkstide<br></br>
              2. Velg ønsket leietid<br></br>
              3. Book og betal med Vipps (for privatkunder). Du mottar videre instrukser på sms<br></br>
              4. Hent utstyr til avtalt tid<br></br>
              5. Lever ved endt leietid (eller før om du ønsker)<br></br>
              <br></br><br></br>
              Leie av utstyr har aldri vært enklere!<br></br><br></br>

            </p>
          </div>
          <div className="categories">
            <div className="category">
              <img src={lifter} alt="Lifter" />
              <h2>Tilhengere</h2>
              <p>Vi tilbyr et bredt spekter av tilhengere for ulike bruksområder og formål.</p>
            </div>
            {/* <div className="category">
              <img src={verktoy} alt="Verktøy" />
              <h2>Utstyr og maskiner</h2>
              <p>Vårt utvalg av verktøy dekker alt du trenger for bygge- og reparasjonsarbeid.</p>
            </div> */}
          </div>
        </div>


      </div>
    </div>
  );
}

export default LandingPage;
