import React, { useState, useEffect } from 'react';
import axios from 'axios';


function AdminProduct() {

  const [product, setProduct] = useState({
    name: '',
    category: '',
    description: '',
    price: '', // Dagsleiepris
    six_hours_price: '', // Nytt felt for 6-timers leie
    weekend_price: '',
    week_price: '',
    image_url: ''
  });

  const [products, setProducts] = useState([]); // Liste over eksisterende produkter
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // Kontroll for modal synlighet
  const [editingProduct, setEditingProduct] = useState(null); // Produkt som skal redigeres

  // Hent kategorier og produkter
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SITE_URL}/api/categories`)
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));

    axios.get(`${process.env.REACT_APP_SITE_URL}/api/equipment`)
      .then(response => setProducts(response.data))
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Hvis feltet er en pris, konverter verdien til et tall
    setProduct({
      ...product,
      [name]: name.includes('price') ? parseFloat(value) || 0 : value
    });
  };

  const handleCategoryChange = (e) => {
    setProduct({ ...product, category: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    axios.post(`${process.env.REACT_APP_SITE_URL}/api/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(response => {
      setProduct({ ...product, image_url: response.data.filePath });
    })
    .catch(error => console.error('Feil ved opplasting av bilde:', error));
  };

  const handleDelete = (productId) => {
    // Vis en bekreftelse før sletting
    const confirmDelete = window.confirm('Er du sikker på at du vil slette dette produktet? Dette kan ikke angres.');

    if (confirmDelete) {
      axios.delete(`${process.env.REACT_APP_SITE_URL}/api/equipment/${productId}`)
        .then(() => {
          // Oppdater produktlisten etter sletting
          setProducts(products.filter(product => product.id !== productId));
        })
        .catch(error => console.error('Feil ved sletting av produkt:', error));
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    // Definer newProduct for å sikre at alle felter er inkludert
    const newProduct = {
      name: product.name,
      category: product.category,
      description: product.description,
      description2: product.description2, // Inkluder description2
      description3: product.description3, // Inkluder description3
      description4: product.description4, // Inkluder description4
      description5: product.description5, // Inkluder description5
      price: product.price || 0, // Standardverdi hvis ikke oppgitt
      six_hours_price: product.six_hours_price || 0, // Standardverdi hvis ikke oppgitt
      weekend_price: product.weekend_price || 0, // Standardverdi hvis ikke oppgitt
      week_price: product.week_price || 0, // Standardverdi hvis ikke oppgitt
      image_url: product.image_url || ''
    };

    // Log for å sjekke verdiene før sending
    console.log('Sending product data:', newProduct);

    // Definer URL og metode
    const submitUrl = editingProduct
      ? `${process.env.REACT_APP_SITE_URL}/api/equipment/${editingProduct.id}`
      : `${process.env.REACT_APP_SITE_URL}/api/equipment`;
    const method = editingProduct ? 'put' : 'post';

    // Send forespørselen til serveren med newProduct
    axios[method](submitUrl, newProduct)
      .then(response => {
        setProducts(editingProduct
          ? products.map(p => (p.id === editingProduct.id ? response.data : p))
          : [...products, response.data]
        );
        setIsModalOpen(false); // Lukk modal etter lagring
        setEditingProduct(null);
      })
      .catch(error => console.error('Feil ved lagring av produkt:', error));
  };



  const handleEdit = (productId) => {
    const productToEdit = products.find(p => p.id === productId);

    // Sett en standardverdi for six_hours_price hvis det er null eller undefined
    setProduct({
      ...productToEdit,
      six_hours_price: productToEdit.six_hours_price || 0 // Standardverdi på 0 hvis det ikke finnes
    });

    setEditingProduct(productToEdit);
    setIsModalOpen(true); // Åpne modal
  };


  const handleNewCategorySubmit = (e) => {
    e.preventDefault();
    axios.post(`${process.env.REACT_APP_SITE_URL}/api/categories`, { name: newCategory })
      .then(response => {
        setCategories([...categories, { id: response.data.id, name: newCategory }]);
        setNewCategory('');
      })
      .catch(error => console.error('Error adding new category:', error));
  };

  return (
    <div className="admin-product-container">
      <div className="admin-product-column">
        <h2>Eksisterende produkter</h2>
        <table>
          <thead>
            <tr>
              <th>Navn</th>
              <th>Kategori</th>
              <th>6-timers leie</th>
              <th>Dagsleie</th>

              <th>Helgeleie</th>

              <th>Handlinger</th>
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <tr key={product.id}>
                <td>{product.name}</td>
                <td>{product.category}</td>
                <td>{product.six_hours_price} kr</td> {/* 6-timers leie */}
                <td>{product.price} kr</td> {/* Dagsleie */}

                <td>{product.weekend_price} kr</td> {/* Helgeleie */}

                <td>
                  <button onClick={() => handleEdit(product.id)}>Rediger</button>
                  <button onClick={() => handleDelete(product.id)} style={{ marginLeft: '10px', color: 'red' }}>Slett</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="admin-product-column">
        <h2>Legg til/oppdater produkt</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Navn:</label>
            <input type="text" name="name" value={product.name} onChange={handleChange} required />
          </div>
          <div>
            <label>Kategori:</label>
            <select name="category" value={product.category} onChange={handleCategoryChange} required>
              <option value="">Velg en kategori</option>
              {categories.map(category => (
                <option key={category.id} value={category.name}>{category.name}</option>
              ))}
            </select>
          </div>
          <div>
            <label>Beskrivelse 1: (Beskrivelse)</label>
            <textarea name="description" value={product.description} onChange={handleChange} required />
          </div>
          <div>
            <label>Beskrivelse 2: (Lasteareal)</label>
            <textarea name="description2" value={product.description2} onChange={handleChange} />
          </div>
          <div>
            <label>Beskrivelse 3: (Nyttelast)</label>
            <textarea name="description3" value={product.description3} onChange={handleChange} />
          </div>
          <div>
            <label>Beskrivelse 4: (Totalvekt)</label>
            <textarea name="description4" value={product.description4} onChange={handleChange} />
          </div>
          <div>
            <label>Utleienummer:</label>
            <textarea name="description5" value={product.description5} onChange={handleChange} />
          </div>
          <div>
            <label>6-timers leie:</label>
            <input type="number" name="six_hours_price" value={product.six_hours_price} onChange={handleChange} required />
          </div>
          <div>
            <label>Dagsleie:</label>
            <input type="number" name="price" value={product.price} onChange={handleChange} required />
          </div>

          <div>
            <label>Helgeleie:</label>
            <input type="number" name="weekend_price" value={product.weekend_price} onChange={handleChange} required />
          </div>


          <div>
            <label>Last opp bilde:</label>
            <input type="file" onChange={handleFileChange} />
            {product.image_url && <p>Bilde lastet opp: {product.image_url}</p>}
          </div>
          <button type="submit">{editingProduct ? 'Oppdater produkt' : 'Legg til produkt'}</button>
        </form>

        <h2>Legg til ny kategori</h2>
        <form onSubmit={handleNewCategorySubmit}>
          <div>
            <input
              type="text"
              placeholder="Ny kategori"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              required
            />
          </div>
          <button type="submit">Legg til kategori</button>
        </form>
      </div>
    </div>
  );

}

export default AdminProduct;
