// src/pages/About.js
import React from 'react';
import liftkorg from '../assets/rentalkey.png';

function About() {
  return (
<div className="vilkar-container">

<div className="omoss-content">
            <div className="omoss-text">
            <h3>Om Oss</h3> <p> Fosen Utleiepartner AS ble etablert med et mål om å gjøre det enklere for privatpersoner å få tilgang til nødvendig utstyr for hverdagslige gjøremål, uten å måtte investere i kostbart utstyr selv. 
              <br></br><br></br>
              Vi så et behov for fleksible løsninger som lar våre kunder leie utstyr når de trenger det, uten stress. Enten du skal gjøre en jobb i hagen, byggeprosjekt hjemme, eller trenger spesialutstyr for kortere perioder.
            Vi tilbyr fleksible utleiemuligheter både når det gjelder tidspunkt og varighet, slik at du kan leie utstyr akkurat når det passer deg. 
            <br></br><br></br>
            Vårt mål er å gjøre hverdagen enklere for våre kunder ved å tilby utstyr av høy kvalitet, tilpasset dine behov, og med en enkel og smidig leieprosess.
            Med Fosen Utleiepartner AS får du tilgang til utstyret du trenger, når du trenger det – alltid med god service og fleksibilitet.
            </p>
            </div>
            <div className="omoss-bilde">
            <img src={liftkorg} alt="Liftkorg" />
    </div>
</div>
</div>
  );
}

export default About;
