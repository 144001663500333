// src/components/Topbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import logo from '../assets/fup-logo.png';

function Topbar() {
  return (
    <div className="topbar">
      <div className="topbar-container">
        <Link to="/" className="logo">
          <img src={logo} alt="UtleieLogo" className="logo-img" />
        </Link>
        <nav>
          <Link to="/products">Produkter</Link>
          <Link to="/terms">Vilkår</Link>
          <Link to="/about">Om Oss</Link>
          <Link to="/contact">Kontakt</Link>
        </nav>
      </div>
    </div>
  );
}

export default Topbar;
