// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Topbar from './components/Topbar';
import Bottombar from './components/Bottombar';  // Importer Bottombar
import LandingPage from './components/LandingPage';
import Products from './components/Products';
import Terms from './components/Terms';
import About from './components/About';
import Contact from './components/Contact';
import AdminProduct from './components/AdminProduct';

function App() {
  const [isBottomVisible, setIsBottomVisible] = useState(false);

  // Funksjon for å sjekke om brukeren har scrollet til bunnen
  const handleScroll = () => {
    const scrollTop = window.scrollY; // Hvor mye som er scrollet
    const windowHeight = window.innerHeight; // Høyden på vinduet
    const fullHeight = document.documentElement.scrollHeight; // Full høyde på dokumentet

    // Hvis brukeren er nær bunnen (innen 100px), vis Bottombar
    if (scrollTop + windowHeight >= fullHeight - 100) {
      setIsBottomVisible(true);
    } else {
      setIsBottomVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Fjern eventlistener når komponenten unmountes
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <Topbar />
      <div style={{ minHeight: '100vh' }}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/products" element={<Products />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/admin-products" element={<AdminProduct />} />
        </Routes>
      </div>
      <Bottombar isVisible={isBottomVisible} /> {/* Vis Bottombar kun hvis isBottomVisible er true */}
    </Router>
  );
}

export default App;
