import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css'; // Importerer styling

const formatDate = (date) => {
  return date.toLocaleDateString('nb-NO', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  });
};

function Products() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Alle');
  const [expandedProductId, setExpandedProductId] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SITE_URL}/api/equipment`)
      .then(response => {
        setProducts(response.data);
        const categories = ['Alle', ...new Set(response.data.map(item => item.category))];
        setCategories(categories);
      })
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  const filteredProducts = selectedCategory === 'Alle'
    ? products
    : products.filter(product => product.category === selectedCategory);

  const handleProductClick = (productId) => {
    setExpandedProductId(expandedProductId === productId ? null : productId);
  };

  const handleCancel = () => {
    setExpandedProductId(null); // Nullstiller det valgte produktet og skjuler detaljraden
  };

  return (
    <div className="products-page">
      <div className="products-container">
        <aside className={`categories-menu ${expandedProductId ? "blurred" : ""}`}>
          <h3>Kategorier</h3>
          <ul>
            {categories.map(category => (
              <li
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={selectedCategory === category ? 'active' : ''}
              >
                {category}
              </li>
            ))}
          </ul>
        </aside>

        <section className="products-list">
          <h1>Produkter</h1>
          {filteredProducts.length > 0 ? (
            <div className="products-grid">
              {filteredProducts.map(product => (
                <div key={product.id}>
                  <div
                    className={`product-row ${expandedProductId && expandedProductId !== product.id ? "blurred" : expandedProductId === product.id ? "selected" : ""}`} // Legg til "selected" for det valgte produktet
                    onClick={() => handleProductClick(product.id)}
                  >
                    <div className="product-image-container">
                      <img src={`${process.env.REACT_APP_SITE_URL}${product.image_url}`} alt={product.name} className="product-image" />

                    </div>
                    <div className="product-info">
                      {product.name && <div className="product-name">{product.name}</div>}
                      {product.description && <div className="product-description">{product.description}</div>}
                      {product.description2 && <div className="product-description2">{product.description2}</div>}
                      {product.description3 && <div className="product-description3">{product.description3}</div>}
                      {product.description4 && <div className="product-description4">{product.description4}</div>}
                    </div>
                    <div className="product-price">
                      {product.six_hours_price && !isNaN(product.six_hours_price) ? (
                        <div className="price-info">6 timer: {Number(product.six_hours_price).toFixed(2)} kr</div>
                      ) : (
                        <div className="price-info">Pris for 6-timers leie ikke tilgjengelig</div>
                      )}

                      {product.price && !isNaN(product.price) ? (
                        <div className="price-info">24 timer: {Number(product.price).toFixed(2)} kr</div>
                      ) : (
                        <div className="price-info">Pris for 24-timers leie ikke tilgjengelig</div>
                      )}

                      {product.weekend_price && !isNaN(product.weekend_price) ? (
                        <div className="price-info">Helg (Fre 16:00 - Søn 23:00): {Number(product.weekend_price).toFixed(2)} kr</div>
                      ) : (
                        <div className="price-info">Helgepris ikke tilgjengelig</div>
                      )}
                    </div>
                  </div>

                  {/* Hvis produktet er valgt, vis detaljraden */}
                  {expandedProductId === product.id && (
                    <ProductDetailsRow product={product} handleCancel={handleCancel} />
                  )}
                </div>
              ))}<br></br><br></br><br></br><br></br><br></br><br></br><br></br>
            </div>
          ) : (
            <p>Ingen produkter funnet i denne kategorien.</p>
          )}
        </section>
      </div>
    </div>
  );
}

function ProductDetailsRow({ product, handleCancel }) {
  const [pickupDate, setPickupDate] = useState(new Date());
  const [pickupTime, setPickupTime] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [returnTime, setReturnTime] = useState(null);
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const times = ['06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];

  useEffect(() => {
    if (pickupDate && pickupTime && returnDate && returnTime) {
      calculatePrice();
    }
  }, [pickupDate, pickupTime, returnDate, returnTime]);

  const handlePickupDateChange = (days) => {
    const newDate = new Date(pickupDate);
    newDate.setDate(newDate.getDate() + days);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (newDate >= today) {
      setPickupDate(newDate);
      setPickupTime(null);
      setReturnDate(null);
      setReturnTime(null);
      setEstimatedPrice(0);
    }
  };

  const handlePickupTime = (time) => {
    setPickupTime(time);
    if (!returnDate) {
      setReturnDate(pickupDate);
    }
  };

  const handleReturnDateChange = (days) => {
    const newDate = new Date(returnDate || pickupDate);
    newDate.setDate(newDate.getDate() + days);
    setReturnDate(newDate);
    setReturnTime(null);
    setEstimatedPrice(0);
  };

  const handleReturnTime = (time) => {
    setReturnTime(time);
    calculatePrice();
  };

  const calculatePrice = () => {
    if (!pickupDate || !pickupTime || !returnDate || !returnTime) return;

    const pickupDateTime = new Date(`${pickupDate.toDateString()} ${pickupTime}`);
    const returnDateTime = new Date(`${returnDate.toDateString()} ${returnTime}`);
    const timeDiff = returnDateTime - pickupDateTime;
    const hoursDiff = timeDiff / (1000 * 60 * 60);

    const sixHoursPrice = parseFloat(product.six_hours_price);
    const dailyPrice = parseFloat(product.price);
    const weekendPrice = parseFloat(product.weekend_price);

    let totalPrice = 0;

    if (hoursDiff <= 6) {
      totalPrice = sixHoursPrice;
    } else if (hoursDiff <= 24) {
      totalPrice = dailyPrice;
    } else {
      const days = Math.floor(hoursDiff / 24);
      const remainingHours = hoursDiff % 24;
      totalPrice = days * dailyPrice + (remainingHours > 6 ? dailyPrice : sixHoursPrice);
    }

    const pickupDay = new Date(pickupDate).getDay();
    const pickupHour = parseInt(pickupTime.split(':')[0], 10);
    const returnDay = new Date(returnDate).getDay();

    if (pickupDay === 5 && pickupHour >= 16 && returnDay <= 0) {
      totalPrice = Math.min(totalPrice, weekendPrice);
    }

    setEstimatedPrice(totalPrice);
  };

  const handleBooking = () => {
    if (!pickupDate || !pickupTime || !returnDate || !returnTime) {
      setErrorMessage('Vennligst fyll inn alle feltene før du fullfører bookingen.');
      return;
    }

    const pickupDateTime = new Date(`${pickupDate.toDateString()} ${pickupTime}`);
    const returnDateTime = new Date(`${returnDate.toDateString()} ${returnTime}`);

    const newBooking = {
      equipment_id: product.id,
      name: product.name,
      customer_name: 'Kundens navn',
      address: 'Kundens adresse',
      booking_start: pickupDateTime,
      booking_end: returnDateTime,
      payment_type: 'Kort',
      payment_value: estimatedPrice,
    };

    axios.post(`${process.env.REACT_APP_SITE_URL}/api/bookings`, newBooking)

      .then(response => {
        alert('Booking lagt til!');
        setErrorMessage('');
      })
      .catch(error => console.error('Feil ved lagring av booking:', error));
  };

  return (
    <div className="product-details-row">
      <div className="row">
        <div className="column-left">
          <p>Velg hentedato og tid for når du ønsker å hente utstyret.</p>
          <div className="date-navigation">
            <button onClick={() => handlePickupDateChange(-1)} className="date-navigation-btn prev-btn">
              &lt;
            </button>
            <span>{pickupDate.toDateString()}</span>
            <button onClick={() => handlePickupDateChange(1)} className="date-navigation-btn next-btn">
              &gt;
            </button>
          </div>

          <div className="time-selection">
            {times.map((time) => {
              const isTimePassed = new Date().toDateString() === pickupDate.toDateString() && new Date().getHours() >= parseInt(time.split(":")[0], 10);
              return (
                <button
                  key={time}
                  onClick={() => !isTimePassed && handlePickupTime(time)}
                  className={pickupTime === time ? "active" : ""}
                  disabled={isTimePassed}
                  style={isTimePassed ? { backgroundColor: '#ccc', color: '#666', cursor: 'not-allowed' } : {}}
                >
                  {time}
                </button>
              );
            })}
          </div>
        </div>

        <div className="column-right">
          {pickupTime && (
            <>
              <p>Velg returdato og tid for når du planlegger å returnere utstyret.</p>
              <div className="date-navigation">
                <button onClick={() => handleReturnDateChange(-1)} className="date-navigation-btn prev-btn">&lt;</button>
                <span>{(returnDate || pickupDate).toDateString()}</span>
                <button onClick={() => handleReturnDateChange(1)} className="date-navigation-btn next-btn">&gt;</button>
              </div>

              <div className="time-selection">
                {times.map((time) => {
                  const pickupDateTime = new Date(`${pickupDate.toDateString()} ${pickupTime}`);
                  const returnDateTime = new Date(`${returnDate?.toDateString() || pickupDate.toDateString()} ${time}`);
                  const timeDiffInHours = (returnDateTime - pickupDateTime) / (1000 * 60 * 60);

                  const isReturnTimeDisabled = returnDateTime <= pickupDateTime || timeDiffInHours < 1;

                  return (
                    <button
                      key={time}
                      onClick={() => !isReturnTimeDisabled && handleReturnTime(time)}
                      className={returnTime === time ? "active" : ""}
                      disabled={isReturnTimeDisabled}
                      style={isReturnTimeDisabled ? { backgroundColor: '#ccc', color: '#666', cursor: 'not-allowed' } : {}}
                    >
                      {time}
                    </button>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="price-booking-row">
        <div className="cancel-booking">
          <button onClick={handleCancel} className="cancel-booking-btn">
            Avbryt
          </button>
        </div>

        <div className="price-and-booking">
          <div className="price-estimate">
            {estimatedPrice > 0 && <h3>Estimert pris: {estimatedPrice} kr</h3>}
          </div>
          {pickupDate && pickupTime && returnDate && returnTime && (
            <button onClick={handleBooking} className="complete-booking-btn">
              Fullfør booking
            </button>
          )}
        </div>
      </div>

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}

export default Products;
