// src/pages/Terms.js
import React from 'react';

function Terms() {
  return (
<div className="vilkar-container">

  <div className="leievilkar-content">

    <h3>FOSEN UTLEIEPARTNER AS – LEIEVILKÅR</h3>

    <h4>1. Beregning av leien</h4>
    <p>
      Leietiden løper fra og med det tidspunkt som er bestemt på leiekontrakten
      og til avtalt leietid utløper eller utstyret er tilbakelevert til Fosen Utleiepartner AS
      adresse. 
    </p>
    <h4>2. Avtalens gyldighet</h4>
    <p>
      Leiekontrakten og leievilkårene trer i kraft da utstyret hentes og/eller
      tas i bruk av kunde evt når utstyret levers på avtalt leveringsadresse.
    </p>
    <h4>3. Utlevering og tilbakelevering</h4>
    <p>
    Utstyret skal hentes av leietaker på utleiers forretningsadresse. 
    Dersom det er avtalt, kan utleier også sørge for transport til den adressen som leietaker ønsker. 
    Kostnadene for transport faktureres i henhold til utleiers gjeldende prisliste. Fosen Utleiepartner AS leverer utstyret klart til bruk, rengjort og i full driftsmessig stand. 
    Hvis utstyret ikke samsvarer med det som er avtalt, plikter leietaker å gi utleier beskjed umiddelbart, slik at utleier kan rette opp eller bytte ut utstyret. 
    Dersom mangelen ikke utbedres, har leietaker rett til å heve avtalen uten varsel. Leietaker må sørge for at utstyret leveres tilbake i tilsvarende driftsklar stand. 
    Skader eller mangler utover normal slitasje vil bli belastet leietaker. Utstyr som er reservert, men ikke hentet, vil bli fakturert for én dags leie.
    </p>
    <h4>4. Leietakers driftsutgifter</h4>
    <p>
    Leietaker er ansvarlig for kostnader til strøm, drivstoff, påfylling av motorolje, frostvæske, destillert vann og lignende. 
    Leietaker må også dekke reparasjon av dekk og hjul, inkludert punkteringer, samt skader på strømkabler. 
    Utstyret skal rengjøres før det returneres, og leietaker dekker kostnader knyttet til forhåndsavtalt forebyggende vedlikehold. 
    Eventuelle reparasjoner av skader som går utover normal slitasje, vil bli belastet leietaker.
    </p>
    <h4>5. Tilsyn og bruk av utstyret</h4>
    <p>
    Leietaker er fullt ansvarlig for følgende:

    At utstyret betjenes av kvalifisert personell som har nødvendig opplæring, kunnskap og erfaring med bruken av utstyret.
    At utstyret brukes korrekt, slik at risiko for personskade på operatør, tredjepart eller skade på eiendom unngås.
    At bruksanvisninger og retningslinjer for utstyret følges nøye.
    At utstyrets oppgitte kapasiteter ikke overskrides.
    At utstyret sikres mot uvedkommende eller ulovlig bruk.
    At utstyret beskyttes mot unødvendige ytre påvirkninger.
    For å hindre unødvendige driftsstopp eller reparasjoner, samt for å forebygge farlige situasjoner, er leietaker forpliktet til å utføre daglig kontroll av utstyret. 
    Dette inkluderer etterfylling av olje, frostvæske, batterivann, med mer. Skriftlige instruksjoner om inspeksjon, vedlikehold og væskekvaliteter kan gis ved behov.

    Hvis det oppdages feil eller utstyret ikke fungerer som det skal, skal Fosen Utleiepartner AS varsles umiddelbart, slik at problemet kan løses før det oppstår ytterligere skader. 
    Leietaker har ikke rett til å gjøre endringer eller modifikasjoner på utstyret uten skriftlig godkjenning fra utleier. Utleier har til enhver tid rett til å inspisere utstyret og må være informert om utstyrets lokasjon under hele leieperioden.
    </p>
    <h4>6. Fremleie</h4>
    <p>
    Leietaker har ikke rett til å fremleie eller overføre sine rettigheter eller forpliktelser i henhold til denne avtalen til tredjepart. 
    Det er heller ikke tillatt å gjøre noen juridiske disposisjoner, som overdragelse eller pantsetting, av det utleide utstyret som eies eller på annen måte disponeres av Fosen Utleieartner AS.
    </p>
    <h4>7. Ansvar, forsikring og skader</h4>
    <p>
    Ansvar: Leietaker har fullt økonomisk ansvar for eventuelle personskader, materielle skader eller følgeskader som måtte oppstå ved bruk av det leide utstyret.

    Utstyrsforsikring: Utstyret er forsikret av Fosen Utleiepartner AS, med mindre annet er avtalt i kontrakten. Forsikringen, med fradrag for egenandel, dekker skader eller tap av utstyret ved:

    Brann (inkludert eksplosjon og lynnedslag).
    Tyveri og hærverk ved innbrudd fra låst område eller bygning (simpelt tyveri er unntatt).
    For bærbart utstyr gjelder beskyttelsesklasse B-2 for bygningen eller rommet hvor det oppbevares (mer informasjon kan fås fra utleier eller forsikringsselskapet).
    Skader som oppstår under transport fra utleier til brukerstedet og under returtransport.
    Utstyrsforsikringen dekker ikke:

    Skader som skyldes uaktsomhet eller mangelfull sikring.
    Skader som oppstår ved bruk i strid med Fosen Utleiepartner AS sine bruksanvisninger eller retningslinjer for utstyret.
    Følgeskader som skyldes manglende vedlikehold, som for eksempel etterfylling av olje, frostvæske eller batterivann.
    Skader/tyveri: Ved skade eller tyveri av utstyret må Fosen Utleiepartner AS varsles umiddelbart. Leietaker er ansvarlig for å begrense skadeomfanget der det er mulig. 
    Dersom leietaker unnlater å melde fra om skaden eller tyveriet så snart som mulig, forbeholder utleier seg retten til å holde leietaker økonomisk ansvarlig for hele tapet, inkludert eventuelle inntektstap.

    Forsikringspremie er spesifisert i gjeldende prisliste.

    Egenandel: Ved skade eller tyveri av utstyr gjelder en egenandel på kr 25 000,-.

    </p>
    <h4>8. Betalingsbetingelser</h4>
    <p>
    For kontokunder er betalingsbetingelsene netto 15 dager om ikke annet er avtalt. Etter forfall beregnes den til enhver tid gjeldene morarente per påbegynt måned. 
    Leieperioder faktureres etterskuddsvis. Ved fakturering av beløp under kr 2000,- eks mva påløper fakturagebyr pålydende kr 65,- for brev og kr 30,- for e-faktura.

    Kontantkunder betaler ved henting av utstyret dersom ikke annet er avtalt.
    </p>
    <h4>9. Oppsigelse</h4>
    <p>
    Leietaker kan forkorte den avtalte leieperioden i henhold til følgende regler:

    For maskiner og utstyr kan leieperioden avkortes med ett arbeidsdags varsel.
    Utstyr som er spesialbestilt for leietaker er bindende for hele den avtalte leieperioden og kan ikke avkortes.
    Dersom leietaker bryter den avtalte leieperioden og pris uten en gyldig grunn, forbeholder Fosen Utleiepartner AS seg retten til å justere prisen.
    Hvis leietaker misligholder sine forpliktelser, inkludert manglende betaling av leie og/eller tillegg til avtalt tid, eller hvis utstyret ikke behandles med nødvendig forsiktighet, kan Fosen Utleiepartner AS umiddelbart si opp avtalen og kreve at utstyret returneres uten forsinkelse.
    </p>
    <h4>10. Ansvarbegrensning</h4>
    <p>
    Fosen Utleiepartner AS garanterer ikke at det utleide utstyret vil oppfylle leietakers spesifikke behov, med mindre dette er uttrykkelig avtalt på forhånd. 
    Fosen Utleiepartner AS fraskriver seg ethvert erstatningsansvar overfor leietaker i forbindelse med leieforholdet. 
    Dette innebærer at selskapet ikke kan holdes ansvarlig for økonomisk tap, tap av bruksrett/arbeidstid, eller for skade på ting, personer eller andre følgeskader som måtte oppstå for leietaker eller tredjepart ved bruk av utstyret. 
    Fosen Utleiepartner AS forbeholder seg retten til å annullere avtalen og kan ikke holdes ansvarlig for forsinkelser eller manglende levering som skyldes forhold utenfor selskapets kontroll (force majeure).
    </p>
    <h4>11. Angrerett</h4>
    <p>
    I henhold til angrerettloven har leietaker rett til å angre leieavtalen innen 14 dager etter avtaleinngåelsen, forutsatt at leieperioden ikke har startet. 
    Angreretten bortfaller imidlertid idet leieperioden begynner, som angitt i leiekontrakten, eller når utstyret er overlevert til leietaker i henhold til avtalen. 
    Leietaker har ingen rett til å angre leieavtalen etter at utstyret er tatt i bruk, selv om dette skjer før den angitte leieperioden formelt starter. 
    For å benytte angreretten må leietaker gi skriftlig melding til Fosen Utleiepartner AS før leieperioden starter.
    </p>
    <h4>12. Personvern</h4>
    <p>
    Fosen Utleiepartner AS er ansvarlig for behandlingen av personopplysninger som samles inn i forbindelse med leieavtaler. 
    Personopplysningene som innhentes vil kun bli brukt til å oppfylle selskapets forpliktelser overfor leietaker, som å administrere leieforholdet og levere utstyr. 
    Opplysningene vil bli lagret i henhold til personvernforordningen (GDPR) og vil ikke bli delt med tredjeparter uten samtykke, med mindre dette er nødvendig for å gjennomføre avtalen eller pålagt av lov. 
    Leietaker har rett til innsyn, retting eller sletting av sine personopplysninger ved å kontakte Fosen Utleiepartner AS.
    </p>
    <h4>13. Forsinkelser og manglende levering</h4>
    <p>
    Fosen Utleiepartner AS plikter å informere leietaker så snart som mulig dersom det oppstår forsinkelser eller utfordringer med å levere utstyret til avtalt tidspunkt. 
    Skulle Fosen Utleiepartner AS være ute av stand til å levere utstyret til avtalt tidspunkt, har leietaker rett til å kansellere avtalen uten ekstra kostnader. 
    Eventuelle forhåndsbetalte beløp vil bli tilbakebetalt i sin helhet dersom avtalen kanselleres som følge av forsinkelsen. Dersom leietaker ønsker det, kan det avtales en ny leveringsdato uten ytterligere kostnader for leietaker.
    <br></br><br></br> <br></br><br></br></p>
  </div>
</div>

  );
}

export default Terms;
